import { createAsync } from '@solidjs/router';
import { Meta } from '@solidjs/meta';
import { Show } from 'solid-js';
import { gql, Error404 } from '../../graphql';
import { FacilityCtx } from '../../providers/facility';
import { getConfigValue } from '../../modules/config';
import { cachedQuery } from '../../graphql/cached-get';
import { ErrorBoundary } from '../../components/error-boundary';
import { Revalidator } from '../../providers/visibility';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function Facility(props: RouteSectionProps) {
	const facility = createAsync(() => getCourse({ id: props.params.facilityId! }), { deferStream: true });

	return (
		<Revalidator keys={['teeTimesMultiV2', 'teeTimePaymentInfo']}>
			<Show when={facility()}>
				<Meta
					name="og:image"
					content={`https://${getConfigValue('MAP_HOST')}/course/${props.params.facilityId}/og.jpg`}
				/>
				<FacilityCtx.Provider value={facility}>
					<ErrorBoundary>{props.children}</ErrorBoundary>
				</FacilityCtx.Provider>
			</Show>
		</Revalidator>
	);
}

export const route = {
	preload: (args) => getCourse({ id: args.params.facilityId! }),
} satisfies RouteDefinition;

const facilityQuery = gql(`
query facility($id: String!) {
	facility(idOrSlug: $id, filterUnavailable: false) {
		...TeeTimeFacilityQuery
		...FacilityLocation
		...TeeTimeAlertForm
		...FacilityHeaderButtons
		id
		slug
		name
		isAvailable
		isBookable
		isFavorite
		latitude
		longitude
		timezone
		supportsTroonAccess
		supportsTroonAccessCourseSiteUpsell
		supportsTroonRewards
		courses {
			...TeeTimeCourseFilter
			supportsTeeTimeAlerts
			id
			name
			bookingWindowDay { year, month, day }
		}
		brand
		type
		metadata {
			description
			phone
			website
			logo { url }
			hero { url }
			gallery { url }
			address { street, city, state, postalCode }
		}
  }
	closures: facilityClosures(facilityIdOrSlug: $id) {
		...FacilityClosureFragment
	}
	notes: facilityNotes(facilityIdOrSlug: $id) {
		...FacilityNoteFragment
	}
}`);

const getCourse = cachedQuery(facilityQuery, {
	onError: (error) => {
		if (error.graphQLErrors[0]?.message.toLowerCase().includes('no facility found')) {
			throw new Error404(error.graphQLErrors[0]);
		}
	},
});
